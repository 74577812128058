<script setup>
const menuVisible = ref(false);
const route = useRoute();
</script>

<template>
  <LazyMenuMobile v-model:visible="menuVisible"/>
  <AppHeaderBar v-if="route.name === 'index'"/>
  <div class="sticky top-0 bg-white shadow-box z-50">
    <Container no-paddings>
      <AppHeaderMenu @hamburgerClick="menuVisible = !menuVisible"/>
      <AppHeaderActionBar :no-switch="true" />
    </Container>
  </div>
</template>
