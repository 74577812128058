<script setup>
const backendStore = useBackendStore();
const {isEmbed} = backendStore;

useHead(() => {
  return isEmbed.value
    ? {meta: [{name: 'robots', content: 'noindex, nofollow'}]}
    : {};
});
</script>

<template>
  <AppHeader v-if="!isEmbed" />
  <slot />
  <AppFooter v-if="!isEmbed" />
  <PoweredByVehis v-else />
  <Blend />
</template>
